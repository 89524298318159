import { FormikErrors } from "formik";
import { LoadingButton } from "@mui/lab";
import { ButtonProps } from "@mui/material";
import useDirection from "@/hooks/useDirection";
import { StyledButton } from "./styles";

type SingleButton = {
  textButton: string | JSX.Element;
  buttonProps: ButtonProps;
  buttonState: "primary" | "secondary";
  isLoading: boolean;
};
type InputWrapperProps = {
  buttons: (SingleButton | undefined)[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: FormikErrors<any>;
};

const InputWrapper = ({ buttons }: InputWrapperProps) => {
  const currentDirection = useDirection();

  return (
    <div
      style={{
        display: "flex",
        marginTop: "-1rem",
        [currentDirection === "rtl" ? "marginRight" : "marginLeft"]: "auto",
      }}
    >
      {(buttons.filter(Boolean) as SingleButton[]).map(({ textButton, buttonProps, isLoading }) => (
        <StyledButton
          type="submit"
          disabled={isLoading}
          {...buttonProps}
          key={`button-${
            typeof textButton === "string" ? textButton : Math.round(Math.random() * 1000)
          }`}
          href=""
        >
          {isLoading ? <LoadingButton loading disabled={false} /> : textButton}
        </StyledButton>
      ))}
    </div>
  );
};

export default InputWrapper;
