import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
  ToolbarType,
} from "@syncfusion/ej2-react-richtexteditor";
import "./syncfusion-richtext-editor-styles.css";
// import { registerLicense } from '@syncfusion/ej2-base';
import { Typography } from "@mui/material";
import { StyledErrorTypography, StyledRichTextBox } from "./styles";

// registerLicense(import.meta.env.VITE_APP_SYNCFUSION_LICENSE_KEY);

interface Props {
  hasError: boolean;
  height?: string;
  onChange?: (value: string) => void;
  initialValue?: string;
  onBlur?: () => void;
  errorText?: string;
  labelText?: string;
}

function CustomRichTextEditor({
  hasError = false,
  onChange,
  initialValue = "",
  height,
  onBlur,
  errorText,
  labelText = "",
}: Props) {
  const items = [
    "Bold",
    "Italic",
    "Underline",
    "StrikeThrough",
    "FontName",
    "FontSize",
    "FontColor",
    "BackgroundColor",
    "LowerCase",
    "UpperCase",
    "|",
    "Formats",
    "Alignments",
    "OrderedList",
    "UnorderedList",
    "Outdent",
    "Indent",
    "|",
    "CreateLink",
    "Undo",
    "Redo",
  ];

  //Rich Text Editor ToolbarSettings
  const toolbarSettings = {
    type: ToolbarType.Expand,
    items: items,
    enableFloating: false,
  };

  let rteObj: RichTextEditorComponent;
  function change(): void {
    onChange && onChange(rteObj.getHtml());
  }

  return (
    <StyledRichTextBox hasError={hasError}>
      {labelText && (
        <Typography sx={{ marginLeft: "2px", fontWeight: 600, fontSize: "14px" }}>
          {labelText}
        </Typography>
      )}
      <RichTextEditorComponent
        toolbarSettings={toolbarSettings}
        ref={richtexteditor => {
          rteObj = richtexteditor!;
        }}
        saveInterval={1}
        change={change}
        value={initialValue}
        height={height}
        blur={onBlur}
      >
        <Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]} />
      </RichTextEditorComponent>
      {hasError && errorText && <StyledErrorTypography>{errorText}</StyledErrorTypography>}
    </StyledRichTextBox>
  );
}
export default CustomRichTextEditor;
