import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const InfoIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  margin: "4px 0px 2px 4px",
  fontSize: "12px",
}));

export const ErrorMsgTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
}));

export const BoxModalStyle = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  backgroundColor: theme.palette.background.default,
  boxShadow: theme.shadows[24],
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  overflowY: "scroll",
  maxHeight: "90%",
}));

interface ConfigTypeProps {
  configType: string;
}

export const StyledBox = styled(Box, {
  shouldForwardProp: prop => prop !== "configType",
})<ConfigTypeProps>(({ theme, configType }) => ({
  backgroundColor: configType === "drawer" ? theme.palette.customGrey.dark : "inherit",
  padding: configType === "drawer" ? "21px 24px" : 0,
  borderBottom: configType === "drawer" ? "1px solid #EEE" : "none",
  boxShadow: configType === "drawer" ? "0 2px 4px #fafafa" : "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

export const TitleTypography = styled(Typography, {
  shouldForwardProp: prop => prop !== "configType",
})<ConfigTypeProps>(({ theme, configType }) => ({
  color: configType === "drawer" ? theme.palette.background.default : theme.palette.primary.main,
}));

export const SubtitleTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.gray.main,
}));

export const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiTypography-root.MuiTypography-subtitle1": {
    fontSize: "12px",
    fontWeight: "400",
    color: theme.palette.error.main,
  },
  "& legend": {
    fontWeight: "600",
  },
}));
