import { LOCALES } from "./locales";
import messages_en from "./en.json";
import messages_fr from "./fr.json";
import messages_ar from "./ar.json";
import amity_ar from "./amity_ar.json";
import { ILA26_IntlMessages } from "@ila-26/ui-kit-open-source";

export const messages = {
  [LOCALES.ENGLISH]: { ...messages_en, ...ILA26_IntlMessages?.en },
  [LOCALES.FRENCH]: { ...messages_fr, ...ILA26_IntlMessages?.fr },
  // TODO: Remove Arabic translations from this file once the Amity SDK exports Arabic translations directly.
  [LOCALES.ARABIC]: { ...messages_ar, ...amity_ar },
};
