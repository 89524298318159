import { ChangeEvent, createRef, FC, useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  TextField,
  Typography,
  Select as MuiSelect,
  InputAdornment,
  CircularProgress,
  Button as MuiButton,
  Autocomplete,
} from "@mui/material";
import {
  Title,
  Header,
  SubTitle,
  SubTitle1,
  SubTitle2,
  DialogActions,
  Button,
  ChipContent,
  CustomChip,
  Code,
  CodeTextField,
  Description,
  Phone,
  ResendCode,
  ResendCodeBox,
  OtpBox,
  OtherButton,
  Dialog,
  DialogContent,
  StyledLinearProgressBar,
  NextButton,
  CheckBoxIcon,
  CheckedBoxIcon,
  ButtonPhoneCode,
  BoxEnterpriseOptionLabel,
  PhoneTextField,
} from "./styles";
import { useIntl } from "react-intl";
import messages from "./messages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowLeft, faLongArrowRight, faMobile } from "@fortawesome/pro-light-svg-icons";
import Select, { ActionMeta, MultiValue } from "react-select";
import AsyncSelect from "react-select/async";
import { faCircle, faCircleCheck, faCircleInfo } from "@fortawesome/pro-solid-svg-icons";
import { Tooltip } from "@components/tooltip";
import countriesData from "@components/phone-country-input/countries.json";
import toast from "react-hot-toast";
import {
  useEmployeeOtpRequestMutation,
  useEmployeeValidateOtpCodeMutation,
  useGetCurrentEmployeeQuery,
  useUpdateEmployeeMutation,
} from "@/store/employee/endpoints";
import otpModalMessages from "@/components/phone-country-input/opt-modal/messages.ts";
import TimerComponent from "@components/phone-country-input/opt-modal/timer-component";
import { enterprisesApiGouvFrUrl } from "@components/user-connection-modal/constants.ts";
import { useSearchActivityDomainQuery } from "@/store/activity-domain/endpoints";
import { ActivityDomain, SubActivityDomain } from "@/types/activity-domain";
import { useSearchSubActivityDomainQuery } from "@/store/sub-activity-domain/endpoints";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import { TenantMembership, TenantPlanType } from "@/store/tenants/endpoints/tenants.ts";
import {
  useGetCurrentEnterpriseQuery,
  useUpdateEnterpriseMutation,
} from "@/store/market-place/endpoints";
import { validationOtpType } from "@components/phone-country-input";
import parsePhoneNumberFromString from "libphonenumber-js";
import { isNumeric } from "@components/phone-country-input/phoneValidationsSchemas.ts";
import { countryCodeToFlagImage } from "@components/phone-country-input/utils.tsx";
import { findAdjacentStep } from "@components/user-connection-modal/utils.ts";
import { useSearchSpecialitiesQuery } from "@/store/specialities/endpoints";
import { EnterpriseSpeciality } from "@/types/store/profile.ts";
import { groupDataByNestedKey } from "@/containers/filter-page-wrapper/utilities.ts";
import HeaderCard from "./header-card";
import useDirection from "@/hooks/useDirection";
import { useTheme } from "@mui/material/styles";
import { customReactSelectStyles } from "../react-select/styles";

interface Props {
  open: boolean;
  handleClose(): void;
}

const countryInitialValue = {
  code: "FR",
  flag: "🇫🇷",
};

interface optionLabelEnterprise {
  nom_complet: string;
  siren: string;
}

const UserConnectionModal: FC<Props> = props => {
  const { formatMessage: __, locale } = useIntl();
  const currentDirection = useDirection();

  const theme = useTheme();
  const selectStyles = customReactSelectStyles(theme);

  const { open, handleClose } = props;

  const { data: employeeData } = useGetCurrentEmployeeQuery();
  const { data: activityDomainData } = useSearchActivityDomainQuery({});
  const { data: subActivityDomainData } = useSearchSubActivityDomainQuery({});
  const { data: specialitiesData } = useSearchSpecialitiesQuery({});

  const [updateEmployee, { isLoading: isUpdatingEmployee }] = useUpdateEmployeeMutation();
  const [updateEnterprise, { isLoading: isUpdatingEnterprise }] = useUpdateEnterpriseMutation();
  const [sendCode, { isLoading: isSendingCode }] = useEmployeeOtpRequestMutation();
  const [confirmCode, { isLoading: isCheckCode }] = useEmployeeValidateOtpCodeMutation();

  const [userStep, setUserStep] = useState<number[]>([]);
  const [progress, setProgress] = useState<number>(20);
  const [index, setIndex] = useState<number>(0);
  const [enterpriseValue, setEnterpriseValue] = useState<any>();
  const [activityDomainValue, setActivityDomainValue] = useState<string>();
  const [subActivityDomainValue, setSubActivityDomainValue] = useState<SubActivityDomain[]>([]);
  const [selectedSpecialities, setSelectedSpecialities] = useState<EnterpriseSpeciality[]>([]);
  const [positionTitle, setPositionTitle] = useState<string | undefined>(undefined);
  const [showMoreActivity, setShowMoreActivity] = useState<boolean>(false);

  const [country, setCountry] = useState<{ code: string; flag: string }>(countryInitialValue);
  const [countryCode, setCountryCode] = useState("+33");
  const [phone, setPhone] = useState("");
  const [validatePhoneNumber, setValidatePhoneNumber] = useState<boolean>(true);
  const [otpValue, setOtpValue] = useState("");
  const [openOtpBox, setOpenOtpBox] = useState(false);
  const [otpStatus, setOtpStatus] = useState<validationOtpType>("otp-not-validated");
  const otpNbValidationField = 6;
  const [otpCode, setOtpCode] = useState(new Array(otpNbValidationField).fill(""));
  const [otpCodeExpired, setOtpCodeExpired] = useState<boolean>(false);
  const [resendOtpCode, setResendOtpCode] = useState<boolean>(false);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState<boolean>(false);
  const otpFieldsReference = useRef<Array<any>>(new Array(6).fill(createRef()));
  const [isVisibleHeaderCard] = useState(new Date() < new Date("2024-10-19"));

  const { tenantsList, tenantId } = useSelector((state: RootState) => state.tenant);
  // is current owner of business or enterprise
  const isCurrentUserOwner = useMemo(
    () =>
      !!tenantsList?.find(
        tenant =>
          tenant.id === tenantId &&
          tenant.membership === TenantMembership.OWNER &&
          tenant.plan !== TenantPlanType.BASIC
      ),
    [tenantsList, tenantId]
  );

  const { data: enterpriseData } = useGetCurrentEnterpriseQuery(undefined, {
    skip: !isCurrentUserOwner,
  });

  const formatEnterpriseOptionLabel = ({ nom_complet, siren }: optionLabelEnterprise) => (
    <BoxEnterpriseOptionLabel>
      <span>{nom_complet}</span>
      <span>Siren: {siren}</span>
    </BoxEnterpriseOptionLabel>
  );

  const handelCallEnterpriseApi = async (value: string) => {
    if (!value || value === "" || value.length < 3) {
      return;
    }
    const data = await fetch(
      `${enterprisesApiGouvFrUrl}/search?limite_matching_etablissements=10&minimal=true&page=1&per_page=10&q=${value}`
    )
      .then(response => response.json())
      .then(final =>
        final.results.filter(
          (enterprise: any) =>
            enterprise.nom_complet.toLowerCase().includes(value.toLowerCase()) ||
            enterprise.siren.toLowerCase().includes(value.toLowerCase())
        )
      );
    return data;
  };

  const handleSubActivityDomainChange = (
    selected: MultiValue<SubActivityDomain>,
    _actionMeta: ActionMeta<SubActivityDomain>
  ) => {
    setSubActivityDomainValue(selected as SubActivityDomain[]);
    setSelectedSpecialities([]);
  };

  const handleSpecialitiesChange = (
    selected: MultiValue<EnterpriseSpeciality>,
    _actionMeta: ActionMeta<EnterpriseSpeciality>
  ) => {
    setSelectedSpecialities(selected as EnterpriseSpeciality[]);
  };

  const filtredActivityDomain = () =>
    showMoreActivity
      ? activityDomainData?.data
      : activityDomainData?.data?.filter(item => !item.isSecondary);

  // Hotfix for the issue of no french companies
  const toSlug = (str: string) => {
    str = str.replace(/^\s+|\s+$/g, ""); // trim
    str = str.toLowerCase();
    const from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    const to = "aaaaeeeeiiiioooouuuunc------";
    for (let i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }
    str = str
      .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
      .replace(/\s+/g, "-") // collapse whitespace and replace by -
      .replace(/-+/g, "-"); // collapse dashes
    return str;
  };

  const handelNextButtonClick = async () => {
    try {
      await updateEmployee({
        id: employeeData?.id,
        enterpriseRecord:
          index === 0
            ? country.code === "FR"
              ? {
                  id: enterpriseValue.siren,
                  name: enterpriseValue.nom_complet,
                }
              : { id: toSlug(enterpriseValue), name: enterpriseValue }
            : undefined,
        activityDomainId: index === 1 ? activityDomainValue : undefined,
        subActivityDomainIds:
          index === 1
            ? subActivityDomainValue.map((item: { id: string }) => item.id) || []
            : undefined,
        specialityIds:
          index === 2
            ? selectedSpecialities.map((item: { id: string }) => item.id) || []
            : undefined,
        jobTitle: index === 2 ? positionTitle : undefined,
        shouldInvalidate: index === userStep.slice(-1)[0] ? undefined : false,
      }).unwrap();
      if (isCurrentUserOwner && [0, 1, 2].includes(index)) {
        await updateEnterprise({
          id: enterpriseData?.id,
          title:
            index === 0 && country.code === "FR"
              ? enterpriseValue.nom_complet
              : index === 0
              ? enterpriseValue
              : enterpriseData?.title,
          legalInformation:
            index === 0 && country.code === "FR" ? { siren: enterpriseValue.siren } : undefined,
          activityDomainId: index === 1 ? activityDomainValue : undefined,
          enterpriseSubActivityDomainIds:
            index === 1
              ? subActivityDomainValue.map((item: { id: string }) => item.id) || []
              : undefined,
          enterpriseSpecialitiesIds:
            index === 2
              ? selectedSpecialities.map((item: { id: string }) => item.id) || []
              : undefined,
        }).unwrap();
      }
      if (index < userStep.slice(-1)[0]) {
        setIndex(prev => findAdjacentStep(userStep, prev, "next") ?? 0);
        setProgress(prevState => prevState + 100 / userStep.length);
      } else if (index === userStep.slice(-1)[0]) {
        toast.success(__(messages.updateEmployeeSuccess));
        handleClose();
      }
    } catch (error) {
      toast.error(__(messages.updateEmployeeError));
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    const code = event.target.value as string;
    setCountryCode(code);
    changeValue(phone, code);
  };

  const handlePhoneNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    let inputNumber = event.target.value;

    if (inputNumber.startsWith("0")) {
      inputNumber = inputNumber.replace(/^0+/, "");
    }

    if (/^\d*$/.test(inputNumber)) {
      setPhone(inputNumber);
      changeValue(inputNumber, countryCode);
    }
    if (!validatePhoneNumber) setValidatePhoneNumber(true);
  };

  const changeValue = (phone: string, code: string) => {
    if (phone) {
      const newPhone = phone.startsWith("0") ? code + phone.slice(1) : code + phone;
      setOtpValue(newPhone);
      setOtpStatus("not-validated");
      if (isNumeric(phone)) {
        const parsedPhoneNumber = parsePhoneNumberFromString(newPhone);
        if (parsedPhoneNumber) {
          const isValid = parsedPhoneNumber.isValid();
          setIsValidPhoneNumber(isValid);
        }
      } else {
        setIsValidPhoneNumber(false);
      }
    } else {
      setOtpValue("");
    }
  };

  const handleClickOtpValidation = async () => {
    if (otpStatus !== "otp-validated") {
      setValidatePhoneNumber(false);
      try {
        await sendCode({
          employeeId: employeeData?.id ?? "",
          phoneNumber: otpValue,
          channel: "sms",
        }).unwrap();
        setOpenOtpBox(true);
        setOtpStatus("otp-not-validated");
        toast.success(__(messages.codeSuccess));
      } catch (error) {
        const Error = error as {
          status: number;
          data: {
            messages: string[];
          };
        };

        if (
          Error &&
          Error.status === 400 &&
          Error.data &&
          Error.data.messages[0] === "The Phone number is already valid"
        ) {
          setOtpStatus("otp-validated");
          toast.success(__(messages.validPhoneNumber));
        } else {
          toast.error(__(messages.codeError));
        }
      }
    }
  };
  const handleOtpChange = (value: string, index: number) => {
    if (/^\d*$/.test(value)) {
      const newValue = [...otpCode];
      newValue[index] = value;
      setOtpCode(newValue);
    }
  };

  const handleResendClick = async () => {
    if (!isSendingCode) {
      try {
        await sendCode({
          employeeId: employeeData?.id ?? "",
          phoneNumber: otpValue,
          channel: "sms",
        }).unwrap();
        otpFieldsReference.current[0].focus();
        setResendOtpCode(true);
        setOtpCodeExpired(false);
        toast.success(__(otpModalMessages.codeSuccess));
      } catch (error) {
        toast.error(__(otpModalMessages.codeError));
      }
    }
  };

  const handleStartValidation = async () => {
    const code = otpCode.join("");
    try {
      await confirmCode({
        code: code,
        employeeId: employeeData?.id ?? "",
        phoneNumber: otpValue,
      }).unwrap();
      setOtpStatus("otp-validated");
      handelNextButtonClick();
      toast.success(__(otpModalMessages.validateCodeSuccess));
    } catch (error) {
      toast.error(__(otpModalMessages.validateCodeError));
    }
  };

  const handleBackspaceAndEnter = (e: any, index: number) => {
    if (
      (e.key === "Enter" || (e.key >= "0" && e.key <= "9")) &&
      e.target.value &&
      index < otpNbValidationField - 1
    ) {
      otpFieldsReference.current[index + 1].focus();
    }
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      otpFieldsReference.current[index - 1].focus();
      otpFieldsReference.current[index - 1].select();
    }
  };

  useEffect(() => {
    if (openOtpBox) {
      const focusTimeout = setTimeout(() => {
        if (otpFieldsReference.current[0]) {
          otpFieldsReference.current[0].focus();
        }
      }, 100);

      return () => clearTimeout(focusTimeout);
    }
  }, [openOtpBox]);

  useEffect(() => {
    if (!employeeData) return;
    const {
      enterprises = [],
      enterpriseRecords = [],
      activityDomain,
      subActivityDomains = [],
      jobTitle,
      specialities = [],
      phone1Valid: hasValidPhoneNumber,
    } = employeeData;

    const hasNotEnterprise = !(
      (enterpriseRecords?.length === 0 &&
        (enterprises ?? []).length > 0 &&
        !enterprises?.some(enterprise => enterprise.title === "Company Name")) ||
      enterpriseRecords?.length > 0
    );

    const step: number[] = [
      hasNotEnterprise && 0,
      (!activityDomain || !subActivityDomains.length) && 1,
      (!jobTitle || !specialities.length) && 2,
      !hasValidPhoneNumber && 3,
    ].filter((step): step is number => step !== false);

    setUserStep(step);
    setIndex(step[0]);
    setProgress(100 / step.length);
    setActivityDomainValue(activityDomain?.id);
    setSubActivityDomainValue(subActivityDomains);
    setPositionTitle(jobTitle);
    setSelectedSpecialities(specialities);
  }, [employeeData]);

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        sx={{ mt: isVisibleHeaderCard ? "60px" : 0 }}
      >
        {isVisibleHeaderCard && <HeaderCard />}

        <StyledLinearProgressBar variant="determinate" value={progress} />
        <DialogContent>
          <Header>
            <Title>{__(messages.title)}</Title>
            <SubTitle>{__(messages.subTitle)}</SubTitle>
          </Header>
          {index === 0 && (
            <Box>
              <SubTitle1>{__(messages.countryQuestion)}</SubTitle1>
              <SubTitle2>{__(messages.countrySubTitle)} *</SubTitle2>
              <Autocomplete
                disablePortal
                value={countriesData.find(data => data.code === country.code)}
                id="country"
                options={countriesData.sort((a, b) =>
                  locale === "fr-FR"
                    ? a.name_f.localeCompare(b.name_f)
                    : a.name.localeCompare(b.name)
                )}
                getOptionLabel={option =>
                  `${locale === "fr-FR" ? `${option.name_f}` : `${option.name}`}`
                }
                filterOptions={(options, state) => {
                  const inputValue = state.inputValue.toLowerCase();
                  return options.filter(option => {
                    const name = locale === "fr-FR" ? option.name_f : option.name;
                    return (
                      name.toLowerCase().includes(inputValue) ||
                      option.code.toLowerCase().includes(inputValue)
                    );
                  });
                }}
                onChange={(_, value, reason) => {
                  if (value)
                    setCountry({
                      code: value.code,
                      flag: value.flag,
                    });
                  if (reason === "clear") setCountry(countryInitialValue);
                  setEnterpriseValue(undefined);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={""}
                    size="small"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <span style={{ marginLeft: "10px" }}>
                          {countryCodeToFlagImage(country.flag)}
                        </span>
                      ),
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Typography fontSize={"14px"} fontWeight={500} variant="body1">
                      <span style={{ marginRight: "10px" }}>
                        {countryCodeToFlagImage(option.flag)}
                      </span>
                      {locale === "fr-FR" ? option.name_f : option.name}
                    </Typography>
                  </li>
                )}
                sx={{
                  width: "100%",
                  maxWidth: "100%",
                  borderRadius: "4px",
                  marginBottom: "15px",
                }}
                ListboxProps={{ style: { maxHeight: 200, overflow: "auto" } }}
              />
              <SubTitle1>{__(messages.enterpriseQuestion)}</SubTitle1>
              <SubTitle2>
                {country.code === "FR"
                  ? __(messages.enterpriseSubTitle) + "*"
                  : __(messages.enterpriseNameSubTitle) + "*"}
              </SubTitle2>
              {country.code === "FR" ? (
                <AsyncSelect
                  loadOptions={handelCallEnterpriseApi}
                  value={enterpriseValue}
                  noOptionsMessage={e => (e.inputValue ? __(messages.noOptions) : null)}
                  placeholder={__(messages.selectEnterprisePlaceHolder)}
                  // @ts-ignore
                  getOptionLabel={option => formatEnterpriseOptionLabel(option)}
                  onChange={e => setEnterpriseValue(e)}
                  styles={{
                    ...selectStyles,
                    control: (provided, state) => ({
                      ...selectStyles.control(provided, state),
                      width: "100%",
                      backgroundColor: "transparent",
                    }),
                    menu: provided => ({
                      ...selectStyles.menu(provided),
                      height: "150px",
                    }),
                    menuList: provided => ({
                      ...provided,
                      height: "150px",
                    }),
                  }}
                />
              ) : (
                <TextField
                  size={"small"}
                  value={typeof enterpriseValue === "string" ? enterpriseValue : undefined}
                  fullWidth
                  placeholder={__(messages.enterpriseNamePlaceHolder)}
                  onChange={event => {
                    const value = event.target.value;
                    setEnterpriseValue(value as string);
                  }}
                />
              )}
            </Box>
          )}
          {index === 1 && (
            <Box sx={{ marginTop: "-15px" }}>
              <SubTitle1>{__(messages.activityDomainTitle)} *</SubTitle1>
              <SubTitle2>{__(messages.oneItemSelection)}</SubTitle2>
              <ChipContent>
                {filtredActivityDomain()?.map((activityDomain: ActivityDomain) => (
                  <CustomChip
                    onClick={() => {
                      setActivityDomainValue(activityDomain.id);
                      setSubActivityDomainValue([]);
                      setSelectedSpecialities([]);
                    }}
                    icon={
                      <Checkbox
                        checked={activityDomainValue === activityDomain.id}
                        icon={<CheckBoxIcon icon={faCircle} />}
                        checkedIcon={<CheckedBoxIcon icon={faCircleCheck} />}
                      />
                    }
                    label={activityDomain.name}
                    variant="outlined"
                    size="small"
                  />
                ))}
                <OtherButton size="small" onClick={() => setShowMoreActivity(prev => !prev)}>
                  {showMoreActivity ? __(messages.showLess) : __(messages.otherActivityDomain)}
                </OtherButton>
              </ChipContent>

              {activityDomainValue && (
                <Box>
                  <SubTitle1>{__(messages.servicesTitle)} *</SubTitle1>
                  <SubTitle2>{__(messages.manyItemsSelection)}</SubTitle2>
                  <Select<SubActivityDomain, true>
                    placeholder={__(messages.select)}
                    isMulti
                    value={subActivityDomainValue}
                    options={
                      subActivityDomainData?.data?.filter(
                        subActivityDomainData =>
                          subActivityDomainData.activityDomain.id === activityDomainValue
                      ) ?? []
                    }
                    getOptionValue={(option: SubActivityDomain) => option.id}
                    getOptionLabel={(option: SubActivityDomain) => option.name}
                    onChange={handleSubActivityDomainChange}
                    menuPortalTarget={document.body}
                    styles={{
                      ...selectStyles,
                      control: (provided, state) => ({
                        ...selectStyles.control(provided, state),
                        width: "100%",
                        backgroundColor: "transparent",
                      }),
                      menuList: provided => ({
                        ...provided,
                        height: "120px",
                      }),
                      menuPortal: base => ({
                        ...base,
                        zIndex: 9999,
                      }),
                    }}
                  />
                </Box>
              )}
            </Box>
          )}
          {index === 2 && (
            <>
              <Box sx={{ marginBottom: "30px" }}>
                <SubTitle1>{__(messages.positionTitle)} *</SubTitle1>
                <TextField
                  size={"small"}
                  value={positionTitle}
                  fullWidth
                  placeholder={__(messages.selectPositionPlaceHolder)}
                  onChange={event => {
                    const value = event.target.value;
                    setPositionTitle(value as string);
                  }}
                />
              </Box>
              <Box>
                <SubTitle1>{__(messages.skills)} *</SubTitle1>
                <SubTitle2>{__(messages.manyItemsSelection)}</SubTitle2>
                <Select<EnterpriseSpeciality, true>
                  placeholder={__(messages.select)}
                  isMulti
                  value={selectedSpecialities}
                  options={
                    specialitiesData?.data
                      ? groupDataByNestedKey(
                          specialitiesData?.data.filter(speciality =>
                            subActivityDomainValue.some(
                              domain => domain.id === speciality.enterpriseSubActivityDomain.id
                            )
                          ),
                          "enterpriseSubActivityDomain.name"
                        )
                      : []
                  }
                  getOptionValue={(option: EnterpriseSpeciality) => option.id}
                  getOptionLabel={(option: EnterpriseSpeciality) => option.label}
                  onChange={handleSpecialitiesChange}
                  styles={{
                    ...selectStyles,
                    control: (provided, state) => ({
                      ...selectStyles.control(provided, state),
                      width: "100%",
                      backgroundColor: "transparent",
                    }),
                    menu: provided => ({
                      ...selectStyles.menu(provided),
                      height: "145px",
                    }),
                    menuList: provided => ({
                      ...provided,
                      height: "145px",
                    }),
                  }}
                />
              </Box>
            </>
          )}
          {index === 3 && (
            <>
              <Box>
                <SubTitle1>{__(messages.phone)} *</SubTitle1>
                <Box sx={{ display: "flex", alignItems: "start", gap: "15px", marginTop: "15px" }}>
                  <FormControl size={"small"} sx={{ borderRadius: "10px 0px 0px 10px" }}>
                    <InputLabel
                      id="demo-simple-select-label"
                      size={"small"}
                      sx={{ fontWeight: 600 }}
                    >
                      <>
                        {__(messages.code)}
                        <Tooltip title={__(messages.countryPhoneCode)} arrow>
                          <FontAwesomeIcon
                            color={theme.palette.primary.main}
                            icon={faCircleInfo}
                            size="xs"
                            style={{ margin: "4px 0px 0px 4px" }}
                          />
                        </Tooltip>
                      </>
                    </InputLabel>
                    <MuiSelect
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={countryCode}
                      label="Country"
                      onChange={handleChange}
                      sx={{
                        width: "105px",
                        maxWidth: "105px",
                        "& .MuiTypography-root.MuiTypography-subtitle1": {
                          fontSize: "12px",
                          fontWeight: 400,
                          color: theme.palette.error.main,
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                          },
                        },
                      }}
                      renderValue={selected => {
                        const flag = countriesData.find(x => x.dial_code === selected)
                          ?.flag as string;
                        return (
                          <Box
                            sx={{
                              display: "flex",
                              gap: 0.7,
                              alignItems: "center",
                            }}
                          >
                            {countryCodeToFlagImage(flag)}
                            <span>{selected}</span>
                          </Box>
                        );
                      }}
                    >
                      {countriesData.map((country, index) => (
                        <MenuItem
                          key={index}
                          value={country.dial_code}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <span style={{ marginRight: "10px" }}>
                            {countryCodeToFlagImage(country.flag)}
                          </span>
                          {locale === "fr-FR" ? country.name_f : country.name}
                          <Typography
                            style={{
                              marginLeft: "10px",
                              color: "gray",
                            }}
                          >
                            {country.dial_code}
                          </Typography>
                        </MenuItem>
                      ))}
                    </MuiSelect>
                  </FormControl>
                  <PhoneTextField
                    name={"phone"}
                    label={__(messages.phoneNumber)}
                    type={"tel"}
                    size={"small"}
                    value={phone}
                    error={Boolean(false)}
                    helperText={!isValidPhoneNumber && phone ? __(messages.error) : ""}
                    placeholder={"123 456 7890"}
                    onChange={handlePhoneNumberChange}
                    InputLabelProps={{
                      sx: { fontWeight: 600 },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={{ marginX: 0 }}>
                          <Box
                            sx={{
                              padding: 0.7,
                            }}
                          >
                            {isValidPhoneNumber ? (
                              otpStatus !== "validated" ? (
                                isSendingCode ? (
                                  <CircularProgress size="1rem" sx={{ marginX: 2 }} />
                                ) : (
                                  <Tooltip
                                    title={
                                      otpStatus === "otp-not-validated"
                                        ? __(messages.otpNotValidated)
                                        : otpStatus === "otp-validated"
                                        ? __(messages.otpValidated)
                                        : __(messages.notValidated)
                                    }
                                    arrow
                                    disableHoverListener={!validatePhoneNumber}
                                  >
                                    <Box>
                                      <ButtonPhoneCode
                                        variant="outlined"
                                        size="small"
                                        startIcon={
                                          <FontAwesomeIcon
                                            icon={faMobile}
                                            size="xs"
                                            style={{ fontSize: "16px" }}
                                          />
                                        }
                                        disabled={!validatePhoneNumber}
                                        onClick={handleClickOtpValidation}
                                      >
                                        <Box
                                          sx={{
                                            fontWeight: "500",
                                            textTransform: "none",
                                          }}
                                        >
                                          {__(messages.validOtpPhoneNumber)}
                                        </Box>
                                      </ButtonPhoneCode>
                                    </Box>
                                  </Tooltip>
                                )
                              ) : (
                                <></>
                              )
                            ) : (
                              <></>
                            )}
                          </Box>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>
              {openOtpBox && otpStatus !== "otp-validated" && (
                <OtpBox>
                  <Description variant="body1">
                    {__(otpModalMessages.description)}
                    <Phone> {otpValue}</Phone> .
                  </Description>
                  <Code>
                    {otpCode.map((digit, index) => (
                      <CodeTextField
                        key={index}
                        value={digit}
                        onChange={e => handleOtpChange(e.target.value, index)}
                        onKeyDown={e => handleBackspaceAndEnter(e, index)}
                        inputProps={{ maxLength: 1 }}
                        inputRef={reference => {
                          otpFieldsReference.current[index] = reference;
                        }}
                        size={"small"}
                      />
                    ))}
                  </Code>
                  <ResendCodeBox>
                    <Typography sx={{ fontSize: "11px" }}>
                      {__(otpModalMessages.timer)}
                      <TimerComponent
                        duration={1}
                        resend={resendOtpCode}
                        setResend={setResendOtpCode}
                        expired={otpCodeExpired}
                        setExpired={setOtpCodeExpired}
                      />
                    </Typography>
                    <Typography sx={{ fontSize: "11px" }}>
                      {__(otpModalMessages.receiveCode)}{" "}
                      <ResendCode
                        disabled={!otpCodeExpired}
                        onClick={otpCodeExpired ? handleResendClick : undefined}
                      >
                        {__(otpModalMessages.resendCode)}
                      </ResendCode>
                    </Typography>
                  </ResendCodeBox>
                  <MuiButton
                    style={{ textTransform: "none", fontSize: "13px" }}
                    variant={"outlined"}
                    disabled={
                      otpCodeExpired ||
                      isSendingCode ||
                      isCheckCode ||
                      otpCode.join("").length < otpNbValidationField
                    }
                    onClick={handleStartValidation}
                    fullWidth
                  >
                    {isCheckCode && <CircularProgress size="1rem" />}
                    {__(otpModalMessages.validButton)}
                  </MuiButton>
                </OtpBox>
              )}
            </>
          )}

          <DialogActions>
            <Button
              onClick={() => {
                if (index > userStep[0]) {
                  setIndex(prev => findAdjacentStep(userStep, prev, "previous") ?? userStep[0]);
                  setProgress(prevState => prevState - 100 / userStep.length);
                }
              }}
              sx={{ visibility: index === userStep[0] ? "hidden" : "visible" }}
              startIcon={
                <FontAwesomeIcon
                  icon={faLongArrowLeft}
                  size={"xs"}
                  style={{
                    rotate: currentDirection === "rtl" ? "180deg" : "0deg",
                  }}
                />
              }
            >
              {__(messages.previousButton)}
            </Button>
            <Tooltip
              title={__(messages.nextButtonInformation)}
              placement="top"
              disableHoverListener={
                !(
                  (index === 0 && !enterpriseValue) ||
                  (index === 1 && (!activityDomainValue || subActivityDomainValue.length === 0)) ||
                  (index === 2 && (!positionTitle || selectedSpecialities.length === 0)) ||
                  (index === 3 && otpStatus !== "otp-validated")
                )
              }
              arrow
              sx={{
                width: "130px",
              }}
            >
              <Box>
                <NextButton
                  onClick={() => handelNextButtonClick()}
                  lastStep={index == userStep.slice(-1)[0]}
                  variant={index === userStep.slice(-1)[0] ? "contained" : "text"}
                  startIcon={
                    index !== userStep.slice(-1)[0] ? (
                      (isUpdatingEmployee || isUpdatingEnterprise) && <CircularProgress size={20} />
                    ) : isUpdatingEmployee || isUpdatingEnterprise ? (
                      <CircularProgress size={20} />
                    ) : (
                      <FontAwesomeIcon
                        icon={faLongArrowRight}
                        size={"xs"}
                        style={{
                          rotate: currentDirection === "rtl" ? "180deg" : "0deg",
                        }}
                      />
                    )
                  }
                  endIcon={
                    index !== userStep.slice(-1)[0] && (
                      <FontAwesomeIcon
                        icon={faLongArrowRight}
                        size={"xs"}
                        style={{
                          rotate: currentDirection === "rtl" ? "180deg" : "0deg",
                        }}
                      />
                    )
                  }
                  disabled={
                    isUpdatingEmployee ||
                    isUpdatingEnterprise ||
                    (index === 0 && !enterpriseValue) ||
                    (index === 1 &&
                      (!activityDomainValue || subActivityDomainValue.length === 0)) ||
                    (index === 2 && (!positionTitle || selectedSpecialities.length === 0)) ||
                    (index === 3 && otpStatus !== "otp-validated")
                  }
                >
                  {index === userStep.slice(-1)[0]
                    ? __(messages.startButton)
                    : __(messages.nextButton)}
                </NextButton>
              </Box>
            </Tooltip>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UserConnectionModal;
