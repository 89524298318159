import { styled } from "@mui/material/styles";
import { CardContent, Stack, Chip, Box, Typography, Card } from "@mui/material";
import { Link } from "react-router-dom";

export const GetMoreLink = styled(Link)(({ theme }) => ({
  ["&"]: {
    color: theme.palette.text.primary,
    fontSize: "12px",
    fontWeight: 500,
    ["&:hover"]: {
      color: theme.palette.primary.main,
    },
  },
}));
export const LayoutCardContent = styled(CardContent)({
  ["&"]: {
    padding: 0,
    margin: 0,
    ["&.MuiCardContent-root:last-child"]: {
      paddingBottom: 0,
    },
  },
});
export const HeaderCard = styled(Stack)({
  ["&"]: {
    padding: "10px 24px",
  },
});
export const WaferCategories = styled(Chip)(
  ({
    wafercolor,
    addmargintop,
    borderratable,
    colorasbackground,
    addmarginbotom,
    fontWeight = 700,
  }: WaferProps) => ({
    ["&"]: {
      backgroundColor: colorasbackground ? `${wafercolor}` : `${wafercolor}1A`,
      color: colorasbackground ? "#fff" : wafercolor,
      fontWeight,
      marginTop: addmargintop,
      borderRadius: borderratable,
      marginBottom: addmarginbotom,
      ["&>*"]: {
        textTransform: "initial",
      },
    },
  })
);
export const BodyCard = styled(Box)({
  padding: "22px 24px",
});

interface WaferProps {
  wafercolor?: string;
  addmargintop?: string;
  borderratable?: string;
  colorasbackground?: boolean;
  addmarginbotom?: string;
  fontWeight?: string | number;
}

export const Title = styled(Typography)(({ theme }) => ({
  ["&"]: {
    color: theme.palette.primary.dark,
    fontSize: "18px",
    fontWeight: "bold",
    marginLeft: 6,
    paddingTop: 6,
  },
}));

export const RatingTitle = styled(Typography)(({ theme }) => ({
  ["&"]: {
    color: theme.palette.primary.main,
    fontSize: "18px",
    fontWeight: "bold",
  },
}));

export const CardWithBorder = styled(Card)(({ theme }) => ({
  ["&"]: {
    border: `1px solid`,
    borderColor: theme.palette.border.main,
    borderRadius: 5,
    boxShadow: "none",
    background: theme.palette.background.default,
    "&:hover": {
      boxShadow: "none",
    },
  },
}));

export const CardCustom = styled(Card)(({ theme }) => ({
  ["&"]: {
    boxShadow: "none",
    border: `1px solid ${theme.palette.text.primary}1A`,
    borderRadius: "3px",
  },
}));

export const HeaderStack = styled(Stack)(({ theme }) => ({
  border: `1px solid`,
  borderColor: theme.palette.border.main,
  padding: "15px",
  marginBottom: "15px",
  borderRadius: "6px",
  backgroundColor: theme.palette.background.default,
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  flex: 1,
  spacing: 12,
  width: "100%",
}));
