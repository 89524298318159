import { styled } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Select, TextField, Typography } from "@mui/material";

export const classesIcon = {
  danger: "bg-danger",
  success: "bg-success",
  warning: "bg-warning",
};

export const ValidationIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  ["&"]: {
    height: "20px",
    width: "20px",
    marginRight: "8px",
    cursor: "pointer",
  },
  [`&.${classesIcon.danger}`]: {
    color: theme.palette.error.dark,
  },
  [`&.${classesIcon.success}`]: {
    color: theme.palette.green.main,
  },
  [`&.${classesIcon.warning}`]: {
    color: theme.palette.warning.light,
  },
}));

export const InfoIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: "12px", // `xs` size equivalent
}));

export const PhoneTextField = styled(TextField)(({ theme }) => ({
  width: "calc(100% - 70px)",
  maxWidth: "calc(100% - 70px)",
  "& .MuiTypography-root.MuiTypography-subtitle1": {
    fontSize: "12px",
    fontWeight: 400,
    color: theme.palette.error.main,
  },
}));

export const CodeTypography = styled(Typography)({
  marginLeft: "10px",
  color: "gray", // equivalent to #808080, doesn't exist in 'palette' yet.
});

export const SelectCode = styled(Select)(({ theme }) => ({
  width: "fit-content",
  "& .MuiTypography-root.MuiTypography-subtitle1": {
    fontSize: "12px",
    fontWeight: 400,
    color: theme.palette.error.main,
  },
}));
