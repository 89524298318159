import { Chip, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CustomTextField = styled(TextField)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  overflowX: "auto",
  paddingTop: "5px",
  "& label": {
    top: 6,
  },
  maxWidth: "100%",
  "& input": {
    minWidth: "150px",
  },
  "&::-webkit-scrollbar": {
    position: "absolute",
    bottom: 0,
    height: "5px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: `${theme.palette.grey[500]}A1`,
    position: "absolute",
    bottom: 0,
    height: "5px",
    cursor: "pointer",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: theme.palette.grey[600],
    position: "absolute",
    bottom: 0,
    height: "5px",
  },
  "&::-webkit-scrollbar-thumb:active": {
    backgroundColor: theme.palette.primary.main,
  },

  "& legend": {
    fontWeight: 600,
  },
}));

export const CustomChip = styled(Chip)(({ theme }) => ({
  marginInline: "5px",
  backgroundColor: `${theme.palette.primary.main}`,
  fontWeight: 500,
  color: `${theme.palette.primary.contrastText}`,
  "& .MuiChip-deleteIcon": {
    color: "white",
    "&:hover": {
      color: theme.palette.violet.contrastText,
    },
  },
}));
