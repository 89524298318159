/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { CSSProperties, ChangeEvent, InputHTMLAttributes, useMemo } from "react";
import Input from "@mui/material/Input";
import { FormikErrors, FormikTouched, FormikValues } from "formik";
import { Box, Grid, Typography } from "@mui/material";
import { ContentFile } from "@/types/content-file";
import { useIntl } from "react-intl";
import messages from "./messages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faDownload } from "@fortawesome/pro-light-svg-icons";
import { faArrowDownToLine } from "@fortawesome/pro-solid-svg-icons";
import {
  faFile,
  faFileAudio,
  faFilePdf,
  faImage,
  faFilePowerpoint,
  faFileVideo,
  faFileSpreadsheet,
  faFileWord,
} from "@fortawesome/pro-solid-svg-icons";
import {
  MainWrapper,
  FileTypeIcon,
  StyledErrorTypography,
  DownloadIcon,
  CloseIcon,
  FileBox,
  SubtitleWrapper,
  ContentWrapper,
  StyledFormControl,
  StyledLabelBox,
  StyledTypography,
  StyledErrorTypography2,
} from "./styles";

interface FileUploaderProps {
  name: string;
  values: FormikValues;
  errors: FormikErrors<{ [key: string]: string[] }>;
  form: {
    setFieldValue: (name: string, value: any) => void;
    touched: FormikTouched<any>;
    setTouched: (
      touched: FormikTouched<any>,
      shouldValidate?: boolean | undefined
    ) => Promise<void | FormikErrors<any>>;
  };
  //   validationSchema: any;
  displayIntVales?: boolean;
  multi?: boolean;
  accepts: string[];
  labelText?: string | JSX.Element;
  subLabelText?: string;
  onDelete?: (id: string) => void;
  attributes: InputHTMLAttributes<HTMLInputElement>;
  style?: CSSProperties;
  displayOnlyImages?: boolean;
}

const FileUploader: React.FC<FileUploaderProps> = ({
  name,
  values,
  form,
  multi,
  displayIntVales = true,
  displayOnlyImages = false,
  accepts,
  errors,
  labelText,
  onDelete,
  attributes,
  style,
  subLabelText,
}) => {
  const { formatMessage: __ } = useIntl();

  const imageTypes = ["jpg", "jpeg", "png", "gif"];
  const pdfTypes = ["pdf"];
  const audioTypes = ["mp3", "wav", "ogg"];
  const powerPointTypes = ["ppt", "pptx"];
  const videoTypes = ["mp4", "avi", "mkv"];
  const spreadsheetTypes = ["xls", "xlsx"];
  const wordDocumentTypes = ["doc", "docx"];

  // TODO: when multi is false, set onDelete to handle the overwritten files
  function getIconForFileType(type: string | null | undefined) {
    let iconName;

    switch (true) {
      case imageTypes.includes(type as string):
        iconName = faImage;
        break;
      case pdfTypes.includes(type as string):
        iconName = faFilePdf;
        break;
      case audioTypes.includes(type as string):
        iconName = faFileAudio;
        break;
      case powerPointTypes.includes(type as string):
        iconName = faFilePowerpoint;
        break;
      case videoTypes.includes(type as string):
        iconName = faFileVideo;
        break;
      case spreadsheetTypes.includes(type as string):
        iconName = faFileSpreadsheet;
        break;
      case wordDocumentTypes.includes(type as string):
        iconName = faFileWord;
        break;
      default:
        iconName = faFile;
    }

    return iconName;
  }

  const isAllImages = useMemo(() => {
    const allFilesTypes = values[name]
      .filter((obj: any) => !(obj instanceof File))
      .map((file: { type?: any }) => file?.type?.toLowerCase()?.substring(1));

    // Add a check to ensure that allFilesTypes is defined before using every()
    return (
      allFilesTypes && allFilesTypes.every((ext: string) => imageTypes.includes(ext?.toLowerCase()))
    );
  }, []);

  const handleDownload = (file: string) => {
    const link = document.createElement("a");
    link.href = file;
    link.target = "_blank";
    link.click();
  };

  return (
    <ContentWrapper sx={{ ...style }}>
      <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>{labelText}</Typography>
      {subLabelText && <Typography variant="caption">{subLabelText}</Typography>}
      <Grid container spacing={1} sx={{ marginTop: labelText ? "5px" : "0px" }}>
        <Grid
          item
          xs={12}
          md={12}
          lg={displayIntVales ? 6 : 12}
          onDragEnter={e => {
            e.preventDefault();
          }}
          onDragOver={e => {
            e.preventDefault();
          }}
          onBlur={() => {
            form.setTouched({ ...form.touched, [name]: true });
          }}
          onDrop={e => {
            e.preventDefault();
            if (!attributes["disabled"]) {
              const newFiles = e.dataTransfer.files;
              if (newFiles) {
                const currentFiles = values[name] || [];
                const handleSingleFileUpdate = (newFiles: any, currentFiles: any) => {
                  if (onDelete && currentFiles.length > 0) {
                    onDelete(currentFiles.map((el: { id: string }) => el.id));
                  }
                  return [newFiles[0]];
                };
                const updatedFiles = multi
                  ? [...newFiles, ...currentFiles]
                  : handleSingleFileUpdate(newFiles, currentFiles);
                form.setFieldValue(name, updatedFiles);
              }
            }
          }}
        >
          <>
            <StyledFormControl fullWidth>
              <Box sx={{ display: "flex", alignItems: "center", height: "38px", gap: ".4rem" }}>
                <StyledLabelBox
                  htmlFor={`fileInput-${name}`}
                  component="label"
                  isDisabled={!!attributes["disabled"]}
                >
                  <StyledTypography
                    variant="subtitle1"
                    style={{
                      cursor: attributes["disabled"] ? "not-allowed" : "pointer",
                    }}
                  >
                    {__(messages.browse)}
                    <FontAwesomeIcon
                      icon={faArrowDownToLine}
                      style={{
                        paddingLeft: "4px",
                        width: "12px",
                        height: "12px",
                      }}
                    />
                  </StyledTypography>
                  <Input
                    type="file"
                    id={`fileInput-${name}`}
                    value={null}
                    style={{ display: "none" }}
                    onClick={(event: React.MouseEvent) => {
                      // Clear the input value to force the onChange event
                      const input = event.target as HTMLInputElement;
                      input.value = "";
                    }}
                    onBlur={() => {
                      form.setTouched({ ...form.touched, [name]: true });
                    }}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      event.preventDefault();
                      event.stopPropagation();
                      const newFiles = event.target?.files;
                      if (newFiles) {
                        const currentFiles = values[name] || [];
                        const handleSingleFileUpdate = (newFiles: any, currentFiles: any) => {
                          if (onDelete && currentFiles.length > 0) {
                            onDelete(currentFiles.map((el: { id: string }) => el.id));
                          }
                          return [newFiles[0]];
                        };
                        const updatedFiles = multi
                          ? [...newFiles, ...currentFiles]
                          : handleSingleFileUpdate(newFiles, currentFiles);
                        form.setFieldValue(name, updatedFiles);
                      }
                    }}
                    inputProps={{
                      multiple: multi,
                      accept: accepts?.join(","),
                      ...attributes,
                    }}
                  />
                </StyledLabelBox>
                <Box
                  sx={{
                    display: "inline-block",
                  }}
                >
                  <Typography fontWeight={400} variant="subtitle1">
                    {attributes["disabled"] ? __(messages.disabled) : __(messages.dragAndDrop)}
                  </Typography>
                </Box>
              </Box>
            </StyledFormControl>
            <Typography sx={{ fontSize: "10.5px", color: "darkslategray", paddingTop: "2px" }}>
              {__(messages.accepts)}: {accepts.join(", ")}
            </Typography>
            {form.touched[name] && !Array.isArray(errors[name]) && errors[name] && (
              <StyledErrorTypography2 variant="caption">
                {" "}
                {String(errors[name])}{" "}
              </StyledErrorTypography2>
            )}
          </>
        </Grid>
        {isAllImages && displayOnlyImages && (
          <Grid item xs={12} md={12} lg={displayIntVales ? 6 : 12} />
        )}
        <Grid item xs={isAllImages && displayOnlyImages ? 12 : 6}>
          <div>
            {(values[name] as (string | File)[])
              .filter(file => file instanceof File)
              .map((file, index) => (
                <div key={index}>
                  {typeof file !== "string" && displayIntVales && (
                    <MainWrapper>
                      <Box
                        sx={{
                          maxWidth: "100%",
                          overflow: "hidden",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {!(isAllImages && displayOnlyImages) || file?.size ? (
                            <>
                              <FileTypeIcon
                                icon={getIconForFileType(
                                  file?.name ? file?.name.split(".").pop() : null
                                )}
                                disabled={!!attributes["disabled"]}
                                readOnly={!!attributes["readOnly"]}
                                style={{ marginRight: "3px" }}
                              />
                              <Box
                                sx={{
                                  height: "38px",
                                  maxHeight: "38px",
                                  maxWidth: "70%",
                                  width: "70%",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {file?.name}
                                </Typography>
                              </Box>
                            </>
                          ) : (
                            <Box>
                              <img
                                loading="lazy"
                                src={(file as unknown as ContentFile)?.azureBlobUri}
                                style={{
                                  maxHeight: "150px",
                                  maxWidth: "200px",
                                  height: "150px",
                                  width: "200px",
                                  marginRight: "auto",
                                  objectFit: "contain",
                                }}
                              />
                            </Box>
                          )}
                        </Box>
                        <Box sx={{ display: "flex" }}>
                          {file?.size && (
                            <Typography sx={{ fontSize: "12px" }} fontWeight={600}>
                              {`${(file?.size / 1024)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, " ")} KB -`}
                            </Typography>
                          )}
                          {/** @ts-ignore */}
                          {(file?.size || errors[name]) && (
                            <StyledErrorTypography
                              //variant="subtitle1"
                              sx={{ fontSize: "12px" }}
                              hasError={
                                (Array.isArray(errors[name]) && !!errors[name]?.[index]) ||
                                (!Array.isArray(errors[name]) && !!errors[name])
                              }
                            >
                              {String(
                                !Array.isArray(errors[name]) && errors[name]
                                  ? errors[name]
                                  : (Array.isArray(errors[name]) && errors[name]?.[index]) ||
                                      __(messages.readyToUpload)
                              )}
                            </StyledErrorTypography>
                          )}
                        </Box>
                      </Box>
                      {attributes["readOnly"] && (
                        <DownloadIcon
                          icon={faDownload}
                          style={{
                            top: isAllImages && displayOnlyImages ? "5px" : "18px",
                            right: isAllImages && displayOnlyImages ? "5px" : "10px",
                          }}
                          onClick={() => {
                            handleDownload((file as unknown as ContentFile)?.azureBlobUri);
                          }}
                        />
                      )}
                      {!attributes["disabled"] && (
                        <CloseIcon
                          icon={faClose}
                          style={{ top: "18px", right: "10px" }}
                          onClick={() => {
                            const itemsToKeep = (values[name] as (ContentFile | File)[]).filter(
                              (_e, i) => i !== index
                            );
                            const itemToDelete = (values[name] as (ContentFile | File)[]).find(
                              (_e, i) => i === index
                            );

                            form.setFieldValue(name, itemsToKeep);
                            if (onDelete) {
                              onDelete((itemToDelete as unknown as ContentFile)?.id);
                            }
                            if (itemsToKeep.length === 0) {
                              form.setTouched({ ...form.touched, [name]: true });
                            }
                          }}
                        />
                      )}
                    </MainWrapper>
                  )}
                </div>
              ))}
          </div>
          <Grid item spacing={2}>
            <Box
              sx={{
                display: isAllImages && displayOnlyImages ? "flex" : undefined,
                width: "100%",
                flexWrap: "wrap",
              }}
            >
              {(values[name] as (string | File)[])
                .filter(file => !(file instanceof File))
                .map((file, index) => (
                  <Box
                    sx={{
                      marginBottom: "10px",
                      marginRight: index % 2 !== 0 ? "0" : "5px",
                      width: isAllImages && displayOnlyImages ? "calc(50% - 5px)" : "100%",
                    }}
                  >
                    {typeof file !== "string" && displayIntVales && (
                      <FileBox>
                        <Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              minWidth: isAllImages && displayOnlyImages ? "100%" : "60%",
                              width: isAllImages && displayOnlyImages ? "100%" : "75%",
                              maxWidth: isAllImages && displayOnlyImages ? "100%" : "80%",
                            }}
                          >
                            {!(isAllImages && displayOnlyImages) || file?.size ? (
                              <>
                                <FileTypeIcon
                                  icon={getIconForFileType(
                                    file?.name ? file?.name.split(".").pop() : null
                                  )}
                                  disabled={!!attributes["disabled"]}
                                  readOnly={!!attributes["readOnly"]}
                                  style={{ marginRight: "5px", marginLeft: "5px" }}
                                />
                                <SubtitleWrapper>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {file?.name}
                                  </Typography>
                                </SubtitleWrapper>
                              </>
                            ) : (
                              <Box>
                                <img
                                  loading="lazy"
                                  src={(file as unknown as ContentFile)?.azureBlobUri}
                                  style={{
                                    maxHeight: "100%",
                                    maxWidth: "100%",
                                    marginRight: "auto",
                                    objectFit: "contain",
                                  }}
                                />
                              </Box>
                            )}
                          </Box>
                          {file?.size && (
                            <Typography variant="subtitle2">
                              {`${(file?.size / 1024)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, " ")} KB -`}
                            </Typography>
                          )}
                          {file?.size && Array.isArray(errors[name]) && errors[name]?.[index] && (
                            <StyledErrorTypography
                              variant="subtitle1"
                              hasError={
                                (Array.isArray(errors[name]) && !!errors[name]?.[index]) ||
                                (!Array.isArray(errors[name]) && !!errors[name])
                              }
                            >
                              {String(
                                (Array.isArray(errors[name]) && errors[name]?.[index]) ||
                                  __(messages.readyToUpload)
                              )}
                            </StyledErrorTypography>
                          )}
                        </Box>
                        {attributes["readOnly"] && (
                          <DownloadIcon
                            icon={faDownload}
                            style={{
                              top: isAllImages && displayOnlyImages ? "5px" : "10px",
                              right: isAllImages && displayOnlyImages ? "5px" : "10px",
                            }}
                            onClick={() => {
                              handleDownload((file as unknown as ContentFile)?.azureBlobUri);
                            }}
                          />
                        )}
                        {!attributes["disabled"] && (
                          <CloseIcon
                            icon={faClose}
                            style={{
                              top: isAllImages && displayOnlyImages ? "5px" : "10px",
                              right: isAllImages && displayOnlyImages ? "5px" : "10px",
                            }}
                            onClick={() => {
                              const itemsToKeep = (values[name] as (ContentFile | File)[]).filter(
                                (_e, i) => i !== index
                              );
                              const itemToDelete = (values[name] as (ContentFile | File)[]).find(
                                (_e, i) => i === index
                              );

                              form.setFieldValue(name, itemsToKeep);
                              if (onDelete) {
                                onDelete((itemToDelete as unknown as ContentFile)?.id);
                              }
                              if (itemsToKeep.length === 0) {
                                form.setTouched({ ...form.touched, [name]: true });
                              }
                            }}
                          />
                        )}
                      </FileBox>
                    )}
                  </Box>
                ))}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </ContentWrapper>
  );
};

export default FileUploader;
