import { styled, Tooltip as MuiTooltip, TooltipProps } from "@mui/material";

export const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  "& .MuiTooltip-tooltip": {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  "& .MuiTooltip-arrow": {
    "&::before": {
      backgroundColor: theme.palette.primary.main,
    },
  },

  "& .MuiTooltip-tooltip svg": {
    color: theme.palette.primary.contrastText,
  },
}));
