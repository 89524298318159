import { TabProps, Tab, Box, Tabs } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";

export const classesTabs = {
  changeTabPosition: "position-right",
  addingBorder: "add-border-bottom",
  selectedItemBg: "selected-item-bg",
};

export const LayoutTabs = styled(Box)(({ height, fontSize }) => ({
  ["&"]: {
    display: "flex",
    justifyContent: "space-between",
    height: height || "50px",
    "& a": {
      paddingBlock: 0,
      height: height || "50px",
      fontSize: fontSize || "14px",
    },
  },
}));

export const TabCustom = styled(Tab)<TabCustomProps>(() => ({
  [`&`]: {
    fontWeight: 500,
    justifyContent: "start",
  },
  [`&.${classesTabs.changeTabPosition}`]: {
    marginLeft: "auto",
    "&:has(svg)": {
      top: "-12px",
    },
  },
}));

export const StyledFirstLevelTabsContainer = styled(Box)(({ theme }) => ({
  margin: "20px 36px 0 36px",
  borderRadius: "6px",
  border: `1px solid ${theme.palette.border.main}`,
  overflow: "hidden",
}));

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.background.default,
  padding: 0,
}));

type TabCustomProps = {
  component?: typeof Link;
  to?: string;
} & TabProps;
