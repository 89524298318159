import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faArrowLeft } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import messages from "./messages";
import { NavigationBox } from "./styles";

type NavigateBackSectionProps = { children?: JSX.Element };
const NavigateBackSection = ({ children }: NavigateBackSectionProps) => {
  const navigate = useNavigate();
  const { formatMessage: __ } = useIntl();
  return (
    <NavigationBox>
      <Box
        sx={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          width: "fit-content",
        }}
        onClick={() => navigate(-1)}
      >
        <FontAwesomeIcon
          icon={faArrowLeft as IconProp}
          style={{ marginRight: "9px", width: "15px", height: "13px" }}
        />
        <Typography fontWeight={400} fontSize="14px">
          {__(messages.back)}
        </Typography>
      </Box>
      {children}
    </NavigationBox>
  );
};

export default NavigateBackSection;
