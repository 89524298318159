const shortLocales = ["ar"]; // locales that need to be in "xx" format

export const updateToFullLocale = (locale: string): string => {
  const localeRegex = /^[a-z]{2}(-[A-Z]{2})?$/;

  if (!localeRegex.test(locale)) {
    throw new Error(`Invalid locale format: ${locale}`);
  }

  // If the locale is "xx", append "-XX"
  if (!locale.includes("-")) {
    return `${locale}-${locale.toUpperCase()}`;
  }

  return locale; // Already in xx-XX format
};

export const updateToShortLocale = (locale: string): string => {
  if (shortLocales.includes(locale.split("-")[0])) {
    return locale.split("-")[0]; // Return only "xx"
  }

  return locale; // Keep the original locale
};
