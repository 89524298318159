import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, styled } from "@mui/material";

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: "70px",
  height: "70px",
  marginLeft: "15px",
  marginBlock: "20px 16px",
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.main,
  fontWeight: 700,
}));

export const Icon = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
}));
