import { FormHelperText, IconButton } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-light-svg-icons";
import { FormikProps, FormikValues } from "formik";
import { tagRegExp } from "./constants";
import { toast } from "react-hot-toast";
import messages from "./messages";
import { useIntl } from "react-intl";
import { CustomChip, CustomTextField } from "./styles";

type TagsInputProps = {
  values: FormikValues;
  form: FormikProps<any>;
  name: string;
  labelText?: string | JSX.Element;
  placeholder?: string;
  attributes: any;
};

const TagsInput = ({ values, form, name, labelText, placeholder, attributes }: TagsInputProps) => {
  const { formatMessage: __ } = useIntl();
  const { sx = {}, helperText, ...otherAttributes } = attributes;

  const [tagInputValue, setTagInputValue] = useState("");
  const inputRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.scrollLeft = inputRef.current.scrollWidth;
    }
  }, [tagInputValue, values]);

  return (
    <>
      <CustomTextField
        ref={inputRef}
        key={`${values?.id || "tag-autocomplete"}`}
        name={name}
        label={labelText}
        placeholder={placeholder || __(messages.placeHolder)}
        value={tagInputValue}
        onBlur={() => {
          form.setTouched({ ...form.touched, [name]: true });
        }}
        onChange={event => {
          setTagInputValue(event.target.value);
        }}
        onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
          if (event.key === "Enter" && tagInputValue) {
            event.preventDefault();

            if (tagRegExp.test(tagInputValue) && tagInputValue.length > 1) {
              form.setFieldValue(name, [...values[name], { text: tagInputValue.trim() }]);
              setTagInputValue("");
            } else {
              toast.error(__(messages.invalidFormat));
            }
          }
        }}
        autoComplete="off"
        sx={{ ...sx }}
        InputLabelProps={{ shrink: true, sx: { fontWeight: 600 } }}
        InputProps={{
          sx: {
            // fontWeight: 600,
            "& .MuiInputBase-input": {
              paddingBlock: "12px",
              fontSize: "15px",
              lineHeight: "1.25rem",
            },
          },
          size: otherAttributes.size || "medium",
          startAdornment: values[name]?.map((tag: { text: string }, index: number) => (
            <CustomChip
              key={tag?.text}
              label={tag?.text}
              onDelete={() => {
                const updatedTags = [...values[name]];
                updatedTags.splice(index, 1);
                form.setFieldValue(name, updatedTags);
              }}
            />
          )),
          endAdornment: values[name]?.length > 0 && (
            <IconButton
              onClick={() => form.setFieldValue(name, [])}
              size="small"
              sx={{
                width: "30px",
                right: -7,
              }}
            >
              <FontAwesomeIcon fontSize="22px" icon={faXmark} />
            </IconButton>
          ),
        }}
        {...otherAttributes}
      />
      <FormHelperText>{helperText || __(messages.helperText)}</FormHelperText>
    </>
  );
};

export default TagsInput;
