import { TextAreaComponent } from "@syncfusion/ej2-react-inputs";
import { FieldInputProps } from "formik";
import "./syncfusion-text-area-styles.css";
import { FormGenConfig } from "@/hooks/useFormGenerator";
import { useState } from "react";
import { Box, styled, Typography } from "@mui/material";

type TextAreaConfigProps = Extract<FormGenConfig[string], { type: "textarea" }>;
type CustomTextAreaProps = {
  labelText: TextAreaConfigProps["labelText"] | undefined;
  field: FieldInputProps<string>;
  rows?: number;
  columns?: number;
  attributes: TextAreaConfigProps["attributes"] & { maxLength?: number };
  isError: boolean;
  onChange: (e: string) => void;
};

const TextLength = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[400],
  fontWeight: 400,
  fontSize: 12,
}));
const CustomTextArea = ({
  labelText,
  field,
  rows = 6,
  columns = 15,
  attributes,
  isError,
  onChange: configOnChange,
}: CustomTextAreaProps) => {
  const [currentValue, setCurrentValue] = useState<string>();
  const onChange = (args: { value: string }) => {
    field.onChange(args);
    setCurrentValue(args.value);
    configOnChange(args.value);
  };

  return (
    <>
      <TextAreaComponent
        cssClass={`${isError ? "e-error" : ""} e-outline`}
        {...field}
        rows={rows}
        cols={columns}
        showClearButton
        floatLabelType="Auto"
        placeholder={typeof labelText === "string" ? labelText : attributes.placeholder}
        onChange={onChange}
        style={{ width: "100%" }}
        maxLength={attributes.maxLength}
        resizeMode="Vertical"
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <TextLength sx={{ fontWeight: 600 }}>{currentValue?.length}</TextLength>
        {attributes.maxLength && <TextLength> /{attributes.maxLength}</TextLength>}
      </Box>
    </>
  );
};

export default CustomTextArea;
