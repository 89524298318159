import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, FormControl, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const ContentWrapper = styled(Box)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

export const MainWrapper = styled(Box)(({ theme }) => ({
  border: `2px dashed ${theme.palette.divider}`,
  borderRadius: "4px",
  minHeight: "42px",
  flexBasis: "100%",
  marginRight: "2px",
  display: "flex",
  alignItems: "center",
  marginBottom: "10px",
  padding: "0 5px",
  position: "relative",
}));

export const FileTypeIcon = styled(FontAwesomeIcon, {
  shouldForwardProp: prop => prop !== "disabled" && prop !== "readOnly",
})<{ disabled: boolean; readOnly: boolean }>(({ theme, disabled, readOnly }) => ({
  color: disabled && !readOnly ? theme.palette.divider : theme.palette.primary.main,
  fontSize: "1.5rem",
}));

export const StyledErrorTypography = styled(Typography, {
  shouldForwardProp: prop => prop !== "hasError",
})<{ hasError: boolean }>(({ theme, hasError }) => ({
  fontWeight: 600,
  color: hasError ? theme.palette.error.main : theme.palette.green.main,
}));

export const DownloadIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  cursor: "pointer",
  border: `1px solid ${theme.palette.primary.main}`,
  color: theme.palette.primary.main,
  borderRadius: "2px",
  width: "14px",
  height: "14px",
}));

export const CloseIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  cursor: "pointer",
  border: `1px solid ${theme.palette.primary.main}`,
  color: theme.palette.primary.main,
  borderRadius: "2px",
  width: "14px",
  height: "14px",
  position: "absolute",
}));

export const FileBox = styled(Box)(({ theme }) => ({
  border: `2px dashed ${theme.palette.divider}`,
  borderRadius: "4px",
  marginRight: "2px",
  display: "flex",
  position: "relative",
  justifyContent: "start",
}));

export const SubtitleWrapper = styled(Box)({
  height: "38px",
  maxHeight: "38px",
  maxWidth: "70%",
  width: "70%",
  display: "flex",
  alignItems: "center",
});

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  border: `2px dashed ${theme.palette.divider}`,
  borderRadius: "4px",
  height: "2.6rem",
  paddingLeft: ".4rem",
}));

export const StyledLabelBox = styled(Box, {
  shouldForwardProp: prop => prop !== "isDisabled" && prop !== "htmlFor",
})<{ isDisabled?: boolean; htmlFor?: string }>(({ theme, isDisabled }) => ({
  backgroundColor: isDisabled ? theme.palette.grey[400] : theme.palette.primary.main,
  padding: "5px 6px",
  display: "flex",
  alignItems: "center",
  cursor: isDisabled ? "default" : "pointer",
  height: "25px",
  borderRadius: "4px",
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.background.paper,
}));

export const StyledErrorTypography2 = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
}));
