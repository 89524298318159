import { alpha, Theme } from "@mui/material";
import { CSSObjectWithLabel } from "react-select";

export const customReactSelectStyles = (theme: Theme) => ({
  control: (provided: CSSObjectWithLabel, state: any) => ({
    ...provided,
    width: "275px",
    fontWeight: 400,
    border: "1px solid",
    "border-color": state.isFocused
      ? theme.palette.primary.main
      : theme.palette.mode === "dark"
      ? `${theme.palette.neutral.main}DD`
      : `${theme.palette.neutral.main}5A`,

    backgroundColor: theme.palette.background.default,
    color: theme.palette.neutral.main,
    boxShadow: state.isFocused ? `0 0 0 1px ${theme.palette.primary.main}` : "none",
    "&:hover": {
      "border-color": state.isFocused ? theme.palette.primary.main : theme.palette.neutral.main,
    },

    ".search-icon": {
      marginLeft: "12px",
    },
  }),
  menu: (provided: CSSObjectWithLabel) => ({
    ...provided,
    zIndex: 1,
    backgroundColor: theme.palette.background.default,
  }),
  input: (base: CSSObjectWithLabel) => ({
    ...base,
    color: theme.palette.text.primary,
  }),

  placeholder: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color:
      theme.palette.mode === "dark"
        ? `${theme.palette.neutral.main}BB`
        : `${theme.palette.neutral.main}5A`,
  }),

  option: (provided: CSSObjectWithLabel, state: { isSelected: boolean; isFocused: boolean }) => ({
    ...provided,
    backgroundColor:
      state.isSelected || state.isFocused ? `${theme.palette.primary.main}1A` : "transparent",
    color: state.isSelected ? theme.palette.primary.main : theme.palette.text.primary,
    cursor: "pointer",
    "&:active": {
      backgroundColor: `${theme.palette.primary.main}1A`, //  when the option is clicked
    },
  }),
  multiValue: (provided: CSSObjectWithLabel) => ({
    ...provided,
    flex: "0 0 auto",
    margin: "2px",
    lineHeight: "16px",
    backgroundColor: alpha(theme.palette.text.primary, 0.1),
  }),
  multiValueLabel: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: theme.palette.neutral.main,
  }),
  singleValue: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: theme.palette.neutral.main,
  }),

  clearIndicator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color:
      theme.palette.mode === "dark"
        ? `${theme.palette.neutral.main}DD`
        : `${theme.palette.neutral.main}5A`,
    "&:hover": {
      color: theme.palette.text.primary,
    },
  }),
  dropdownIndicator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color:
      theme.palette.mode === "dark"
        ? `${theme.palette.neutral.main}DD`
        : `${theme.palette.neutral.main}5A`,
    "&:hover": {
      color: theme.palette.text.primary,
    },
  }),
  container: (provided: CSSObjectWithLabel) => ({
    ...provided,
    "& .select__value-container::-webkit-scrollbar": {
      height: "2px",
    },
    "& .select__value-container::-webkit-scrollbar-track": {
      background:
        theme.palette.mode === "light"
          ? theme.palette.customLightGrey.light
          : theme.palette.gray.contrastText,
    },
    "& .select__value-container::-webkit-scrollbar-thumb": {
      borderRadius: "1px",
    },
  }),

  groupHeading: (provided: CSSObjectWithLabel) => ({
    ...provided,
    fontSize: "12px",
    fontWeight: "bold",
    color: theme.palette.primary.main,
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    maxHeight: 36,
    overflowX: "auto",
    overflowY: "hidden",
    flexWrap: "nowrap",
    "&::-webkit-scrollbar": {
      height: "6px",
    },
    "&::-webkit-scrollbar-track": {
      background:
        theme.palette.mode === "light"
          ? theme.palette.customLightGrey.light
          : theme.palette.gray.contrastText,
      marginLeft: 8,
    },
    "&::-webkit-scrollbar-thumb": {
      paddingLeft: "30px",
      marginLeft: "30px",
      borderRadius: "1px",
    },
    "& > div": {
      flex: "0 0 auto",
    },
    "& > div[class*='-Input']": {
      position: "relative",
      transform: "none",
    },
  }),
});
