import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const NavigationBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: "6px",
  border: `solid 1px`,
  borderColor : theme.palette.border.main,
  padding: "22px  18px",
  display: "flex",
  justifyContent: "space-between",
}));
