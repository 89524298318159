import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledRichTextBox = styled(Box, {
  shouldForwardProp: prop => prop !== "hasError",
})<{ hasError?: boolean }>(({ theme, hasError }) => ({
  maxWidth: "100%",
  "& .e-richtexteditor.e-rte-tb-expand": {
    border: hasError ? `1px ${theme.palette.error.main} solid` : "",
    borderRadius: "4px",
    overflow: "hidden",
  },
  "& .e-richtexteditor.e-rte-tb-expand .e-rte-content, .e-richtexteditor.e-rte-tb-expand .e-source-content":
    {
      borderBottom: hasError ? "none" : "",
    },
}));

export const StyledErrorTypography = styled(Typography)(({ theme }) => ({
  marginTop: "5px",
  fontSize: "12px",
  color: theme.palette.error.main,
}));
