import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledButton = styled(Button)(({ theme }) => ({
  marginLeft: "8px",
  borderRadius: 0,
  boxShadow: "none",
  ["&:disabled"]: {
    background: theme.palette.customGrey.dark,
    cursor: "not-allowed",
  },
}));
