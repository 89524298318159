import {
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useIntl } from "react-intl";
import { Outlet, useParams } from "react-router-dom";
import { TabData } from "@/types/tab-data";
import { ContainerPages } from "@pages/store/styles";
import TabsPage from "@components/layout/tabs";
import { useGetOfferByIdQuery } from "@/store/offers/endpoints";
import { getInitials } from "@/utils/UI/getInitials";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/pro-duotone-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useRelativeTimeFormatter } from "@/hooks/useGetDateMessage";
import { faEllipsis } from "@fortawesome/pro-solid-svg-icons";
import { useState } from "react";
import { faPenToSquare } from "@fortawesome/pro-light-svg-icons";
import { useOffersForm } from "@/pages/store/sevicesOffers-page/useOffersForm";
import messages from "./messages";
import useAppAbility from "@/casl/useAppAbility";
import useSafeQuery from "@/store/error-handler";
import NavigateBackSection from "@/components/navigate-back";
import { HeaderStack } from "@/components/card/styles";
import { Icon, StyledAvatar } from "./styles";

const OfferApplicationDetailsLayout = () => {
  const { formatMessage: __ } = useIntl();

  const { ability } = useAppAbility();

  const { Form, openForm } = useOffersForm();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const toggleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(v => (v ? null : event.currentTarget));
  };

  const { offerId } = useParams();

  const { data: offerData, isFetching } = useSafeQuery(
    useGetOfferByIdQuery,
    { id: offerId as string },
    {
      skip: !offerId,
    }
  );

  const tabs: TabData[] = [
    {
      label: `${__(messages.offerDetails)}`,
      url: ``,
    },
  ];

  if (ability?.can("Update", "OfferApplications")) {
    tabs.push({
      label: `${__(messages.applicationsReceived)}`,
      url: `applications-received`,
    });
  }

  const publicationDate = useRelativeTimeFormatter(offerData?.publicationDate);

  return (
    <Box pb="30px">
      <Grid item xs={12} marginBottom="10px">
        <NavigateBackSection />
      </Grid>

      {isFetching ? (
        <Box sx={{ display: "flex", flexDirection: "column" }} gap="16px">
          <Skeleton variant="rounded" height="170px" />
        </Box>
      ) : (
        <HeaderStack
          sx={{
            pb: 0,
            alignItems: "start",
          }}
        >
          <Stack>
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <StyledAvatar aria-label="avatar" alt="Remy Sharp" src={offerData?.title}>
                {getInitials(offerData?.title || "").toUpperCase()}
              </StyledAvatar>

              <Stack
                direction="column"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  textAlign: "start",
                }}
              >
                <Typography
                  fontWeight={600}
                  variant="subtitle2"
                  sx={{ marginBottom: "1px" }}
                  noWrap
                >
                  {offerData?.title}
                </Typography>

                <Box display="flex" alignItems="center" gap="1rem">
                  <Box display="flex" alignItems="center">
                    <FontAwesomeIcon
                      icon={faLocationDot as IconProp}
                      style={{ width: "12px", height: "12px", marginRight: "5px" }}
                    />
                    <Typography fontSize="13px" fontWeight={500}>
                      {offerData?.address?.city}
                    </Typography>
                  </Box>
                </Box>

                <Typography fontSize="13px" color="text.secondary" pt="4px">
                  {`${__(messages.publishedTime)} ${publicationDate}`}
                </Typography>
              </Stack>
            </Box>

            <TabsPage tabsContent={tabs} removeLastTabRight />
          </Stack>
          {ability?.can("Update", "OfferApplications") && (
            <Box>
              <IconButton
                color="inherit"
                size="small"
                onClick={e => {
                  e.stopPropagation();
                  toggleMenu(e);
                }}
              >
                <FontAwesomeIcon
                  icon={faEllipsis}
                  style={{
                    cursor: "pointer",
                  }}
                />
              </IconButton>

              <Menu
                open={open}
                onClose={toggleMenu}
                anchorEl={anchorEl}
                elevation={1}
                MenuListProps={{
                  sx: {
                    padding: 0,
                    borderRadius: "6px",
                  },
                }}
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <MenuItem
                  onClick={e => {
                    openForm(offerId);
                    toggleMenu(e);
                  }}
                  sx={{
                    display: "flex",
                    alignItems: "start",
                    gap: ".7rem",
                  }}
                >
                  <Icon icon={faPenToSquare} />
                  <Typography variant="subtitle2">Modifier l’offre</Typography>
                </MenuItem>
              </Menu>
            </Box>
          )}
        </HeaderStack>
      )}

      <ContainerPages padding={"5px 5px"}>
        <Outlet />
      </ContainerPages>

      {Form}
    </Box>
  );
};
export default OfferApplicationDetailsLayout;
