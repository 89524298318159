import { Box, FormHelperText, useTheme } from "@mui/material";
import { FormikErrors } from "formik";
import { useState } from "react";
import ReactSelect, { Props as ReactSelectProps } from "react-select";
import useDirection from "@/hooks/useDirection";
import { customReactSelectStyles } from "./styles";

interface FloatingLabelSelectProps<T> extends ReactSelectProps<T> {
  label?: string;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[] | null; // Include errorText here
  helperText?: string;
}

const OutlinedReactSelect = <T,>({
  label,
  error,
  onBlur: propOnBlur,
  helperText,
  ...props
}: FloatingLabelSelectProps<T>) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const currentDirection = useDirection();
  const theme = useTheme();
  const selectStyles = customReactSelectStyles(theme);

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      {label && (
        <label
          style={{
            position: "absolute",
            [currentDirection === "rtl" ? "right" : "left"]: "12px",
            top: "-8px",
            fontSize: "12px",
            background: props?.isDisabled
              ? "transparent"
              : "linear-gradient(to bottom, transparent 50%, #fff 50%)",
            padding: "0 4px",
            height: "14px",
            transition: "all 0.2s ease-in-out",
            zIndex: 4,
            fontWeight: 600,
            color: isFocused ? theme.palette.primary.main : props?.isDisabled ? "#999" : "#000",
            borderRadius: "5px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {label}
        </label>
      )}

      <ReactSelect
        styles={{
          ...selectStyles,
          control: (provided, state) => ({
            ...selectStyles.control(provided, state),
            width: "100%",
          }),
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={event => {
          setIsFocused(false);
          propOnBlur && propOnBlur(event);
        }}
        {...props}
      />
      {error && (
        <span style={{ color: theme.palette.error.main, fontSize: "12px" }}>{error as string}</span>
      )}

      {Boolean(helperText) && <FormHelperText sx={{ pl: 1 }}>{helperText}</FormHelperText>}
    </Box>
  );
};

export default OutlinedReactSelect;
