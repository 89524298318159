import { FC, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { TabData } from "@ilan-types/tab-data";
import { classesTabs, LayoutTabs, StyledTabs, TabCustom } from "./styles";
import { capitalizeText } from "@/utils/misc";
import theme from "@/theme/theme";

const TabsPage: FC<Props> = props => {
  const {
    tabsContent,
    removeLastTabRight,
    verticalTabs,
    removeBorder,
    labelFontSize,
    tabHeight,
    allowScroll,
    selectedItemBg,
    nestedBasePaths,
  } = props;

  const location = useLocation();

  const currentIndex = tabsContent?.findIndex(e => e.url === location.pathname) || 0;
  const [value, setValue] = useState(currentIndex < 0 ? 0 : currentIndex);

  useEffect(() => {
    if (location.pathname.startsWith("/store/company")) {
      setValue(tabsContent?.findIndex(e => e.url === "/store/company") || 0);
      return;
    }
    if (location.pathname.startsWith("/store/offers")) {
      setValue(tabsContent?.findIndex(e => e.url === "/store/offers") || 0);
      return;
    }
    if (location.pathname.startsWith("/sav-pro/management")) {
      setValue(tabsContent?.findIndex(e => e.url === "/sav-pro/management") || 0);
      return;
    }
    if (!location.pathname.includes("settings") && !location.pathname.includes("details")) {
      if (location.pathname.includes("catalog/products")) {
        const currentIndex = tabsContent?.findIndex(e => e.url === "/store/catalog") || 0;
        setValue(currentIndex < 0 ? 0 : currentIndex);
      } else {
        const currentPath = location.pathname;

        // Check if the current path matches any of the nestedBasePaths
        const isNestedPath = nestedBasePaths?.some(path => currentPath.startsWith(path));

        if (isNestedPath) {
          // Find the longest matching base path
          const matchedBasePath = nestedBasePaths?.find(path => currentPath.startsWith(path));

          if (matchedBasePath) {
            // Find the corresponding tab index for the matched base path
            const matchedTabIndex = tabsContent?.findIndex(tab => tab.url === matchedBasePath);

            if (matchedTabIndex !== undefined && matchedTabIndex !== -1) {
              setValue(matchedTabIndex);
              return;
            }
          }
        }

        // If no nested path is matched, find the index based on the current pathname
        const currentIndex = tabsContent?.findIndex(tab => tab.url === currentPath) || 0;
        setValue(currentIndex);
      }
    }
  }, [location, tabsContent, nestedBasePaths]);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <LayoutTabs
      className={`${!verticalTabs && !removeBorder && classesTabs.addingBorder}`}
      height={tabHeight}
      fontSize={labelFontSize}
    >
      <StyledTabs
        orientation={`${verticalTabs ? "vertical" : "horizontal"}`}
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant={allowScroll ? "scrollable" : "standard"}
        scrollButtons={allowScroll ? "auto" : undefined}
        sx={{
          minHeight: tabHeight || "50px",
        }}
      >
        {tabsContent?.map((tab, index) => (
          <TabCustom
            icon={tab.icon}
            iconPosition="start"
            key={index}
            label={typeof tab.label === "string" ? capitalizeText(tab.label) : tab.label}
            component={Link}
            to={tab.url}
            sx={{
              textTransform: "none",
              background:
                !removeLastTabRight && index === tabsContent.length - 1
                  ? theme.palette.customGrey.dark
                  : "unset",
            }}
            className={
              removeLastTabRight
                ? selectedItemBg
                  ? classesTabs.selectedItemBg
                  : ""
                : index === tabsContent.length - 1
                ? classesTabs.changeTabPosition
                : selectedItemBg
                ? classesTabs.selectedItemBg
                : ""
            }
            onClick={() => setValue(index)}
          />
        ))}
      </StyledTabs>
    </LayoutTabs>
  );
};

interface Props {
  tabsContent: Array<TabData> | undefined;
  removeLastTabRight?: boolean;
  verticalTabs?: boolean;
  removeBorder?: boolean;
  selectedItemBg?: boolean;
  tabHeight?: string | number;
  labelFontSize?: string | number;
  allowScroll?: boolean;
  nestedBasePaths?: string[];
}

export default TabsPage;
