import { styled } from "@mui/material/styles";
import {
  LinearProgress as MuiLinearProgress,
  linearProgressClasses,
  Typography,
  Box,
  Button as MuiButton,
  Chip,
  TextField,
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  paperClasses,
  backdropClasses,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Dialog = styled(MuiDialog)(({ theme }) => ({
  [`& .${paperClasses.root}`]: {
    border: "1px solid rgba(0, 0, 0, 0.3)",
    overflow: "visible",
  },
  [`& .${backdropClasses.root}`]: {
    backgroundColor: theme.palette.background.default,
  },
}));

export const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  paddingX: "30px",
  paddingTop: "40px",
  width: "671px",
  height: "590px",
  paddingBottom: "80px",
}));

export const LinearProgress = styled(MuiLinearProgress)(({ theme }) => ({
  ["&"]: {
    height: "8px",
    background: "white",
    [`& .${linearProgressClasses.bar}`]: {
      background: `linear-gradient(to right, ${theme.palette.customBlue.main}, ${theme.palette.primary.main})`,
    },
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  ["&"]: {
    background: `linear-gradient(to right,${theme.palette.customBlue.main}, ${theme.palette.primary.main})`,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    textFillColor: "transparent",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "24px",
    letterSpacing: "0.15px",
    marginBottom: "10px",
  },
}));

export const Header = styled(Box)({
  ["&"]: {
    width: "100%",
    textAlign: "center",
    marginBottom: "35px",
  },
});

export const SubTitle = styled(Typography)({
  ["&"]: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "19px",
    letterSpacing: "0.15px",
    width: "349px",
    marginLeft: "calc((100% - 349px) / 2)",
  },
});

export const SubTitle1 = styled(Typography)({
  ["&"]: {
    fontSize: "16px",
    fontWeight: 600,
    marginBottom: "4px",
  },
});

export const SubTitle2 = styled(Typography)({
  ["&"]: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "19px",
    letterSpacing: "0.15px",
    marginBottom: "8px",
    fontStyle: "italic",
  },
});

export const DialogActions = styled(Box)({
  ["&"]: {
    padding: "40px 30px",
    display: "flex",
    justifyContent: "space-between",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: "90px",
    alignItems: "start",
  },
});

export const Button = styled(MuiButton)(({ theme }) => ({
  ["&"]: {
    color: theme.palette.neutral.main,
    textTransform: "capitalize !important",
    fontWeight: 600,
  },
}));

export const ChipContent = styled(Box)({
  width: "100%",
  maxWidth: "100%",
  minHeight: "calc(100% - 10px)",
  height: "calc(100% - 10px)",
  maxHeight: "calc(100% - 10px)",
  display: "flex",
  flexWrap: "wrap",
  gap: "5px",
  alignContent: "flex-start",
  overflow: "hidden",
  marginBottom: "15px",
});

export const CustomChip = styled(Chip)(({ theme }) => ({
  padding: "5px 5px 5px 0px",
  color: theme.palette.text.primary,
  backgroundColor:
    theme?.palette.mode === "dark"
      ? theme.palette.background.default
      : theme.palette.customLightGrey.main,
  fontSize: "12px",
  fontWeight: 400,
  borderRadius: "4px",
  height: "30px",
  border: `1px solid`,
  borderColor: theme.palette.border.main,
  "& .MuiChip-label": {
    padding: "0px 5px 0px 0px",
    textTransform: "lowercase",
    fontWeight: 500,
  },
  "& .MuiChip-label:first-letter": {
    textTransform: "capitalize !important",
  },
}));

export const OtpBox = styled(Box)(({ theme }) => ({
  ["&"]: {
    border: "1px solid rgba(0, 0, 0, 0.1)",
    background: theme.palette.mode==="dark" ? theme.palette.background.default : `${theme.palette.customLightGrey.light}FF`,
    color: theme.palette.neutral.main,
    width: "100%",
    maxWidth: "480px",
    height: "213px",
    borderRadius: "4px",
    marginTop: "10px",
    padding: "15px 30px",
  },
}));

export const Description = styled(Typography)(({ theme }) => ({
  ["&"]: {
    color: theme.palette.neutral.main,
    fontSize: "14px",
    width: "440px",
  },
}));

export const Phone = styled("span")(({ theme }) => ({
  ["&"]: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
}));

export const Code = styled(Box)({
  ["&"]: {
    display: "flex",
    justifyContent: "space-between",
    margin: "20px 0px 0px 0px",
    width: "350px",
  },
});

export const CodeTextField = styled(TextField)(({ theme }) => ({
  ["&"]: {
    width: "40px",
    height: "40px",
    background: theme.palette.background.default,
    ".MuiInputBase-input": {
      fontSize: "18px",
      fontWeight: "bold",
      color: theme.palette.primary.main,
      textAlign: "center",
    },
  },
}));

export const ResendCodeBox = styled(Box)({
  ["&"]: {
    display: "flex",
    justifyContent: "space-between",
    margin: "15px 0px 20px 0px",
    width: "350px",
  },
});

export const ResendCode = styled("span")<{ disabled?: boolean }>(({ theme, disabled }) => ({
  ["&"]: {
    color: disabled ? "grey" : theme.palette.primary.main,
    fontWeight: "bold",
    textDecoration: "underline",
    cursor: disabled ? "default" : "pointer",
  },
}));

export const OtherButton = styled(MuiButton)(({ theme }) => ({
  ["&"]: {
    border: `1px solid ${theme.palette.primary.main}FF`,
    background: `${theme.palette.primary.main}19`,
    color: `${theme.palette.primary.main}FF`,
    textTransform: "capitalize !important",
    fontWeight: 600,
    borderRadius: "4px",
    height: "30px",
    padding: "0px 12px",
  },
}));

export const StyledLinearProgressBar = styled(LinearProgress)(({ theme }) => ({
  [`&.${linearProgressClasses.determinate}`]: {
    backgroundColor: theme.palette.info.main,
  },
}));

export const NextButton = styled(MuiButton)<{ lastStep: boolean }>(({ theme, lastStep }) => ({
  color: lastStep ? theme.palette.common.white : theme.palette.neutral.main,
  fontWeight: 600,
  textTransform: "none",
  zIndex: 1,
}));

export const CheckBoxIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.neutral.light,
  fontSize: "15px",
}));

export const CheckedBoxIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: "15px",
}));

export const ButtonPhoneCode = styled(MuiButton)(({ theme }) => ({
  borderRadius: "3px",
  color: `${theme.palette.primary.main} !important`,
  backgroundColor: `${theme.palette.primary.main}1a`,
  "&:hover": {
    backgroundColor: `${theme.palette.primary.main}33`,
  },
  "&:disabled": {
    color: `${theme.palette.grey[500]} !important`,
    backgroundColor: `${theme.palette.customGrey.dark}`,
  },
}));

export const BoxEnterpriseOptionLabel = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  color: theme.palette.text.primary,
  "& span:nth-of-type(2)": {
    color: theme.palette.customGrey.contrastText,
  },
}));

export const PhoneTextField = styled(TextField)(({ theme }) => ({
  width: "calc(100% - 70px)",
  maxWidth: "calc(100% - 70px)",
  "& .MuiOutlinedInput-root": {
    paddingRight: 0,
  },
  "& .MuiTypography-root.MuiTypography-subtitle1": {
    fontSize: "12px",
    fontWeight: 400,
    color: theme.palette.error.main,
  },
}));
