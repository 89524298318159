import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const StyledTextField = styled(TextField)(({ theme }) => ({
  ["&"]: {
    backgroundColor: theme.palette.background.default,
    borderRadius: "4px",
  },
}));

export const StyledIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  ["&"]: {
    color: theme.palette.grey[300],
  },
}));
