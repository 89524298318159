import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Chip } from "@mui/material";
import { styled } from "@mui/material/styles";

export const InfoIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: "12px",
}));

export const CustomChip = styled(Chip)(({ theme }) => ({
  marginRight: "3px",
  marginBottom: "10px",
  backgroundColor: theme.palette.violet.light,
  border: `0px solid ${theme.palette.neutral.main}`,
  borderRadius: "5px",
  fontWeight: "600",
}));

export const ContentBox = styled(Box)(({ theme }) => ({
  minHeight: "100px",
  padding: "5px",
  border: `1px solid ${theme.palette.neutral.main}`,
  borderRadius: "5px",
  width: "100%",
}));

export const ColorPickerWrapper = styled(Box)(({ theme }) => ({
  height: "30px",
  width: "70px",
  padding: "5px",
  border: `1px solid ${theme.palette.neutral.main}`,
  borderRadius: "5px",
}));
