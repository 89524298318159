import { useIntl } from "react-intl";
import {
  DatePickerComponent,
  DatePickerModel,
  DateTimePickerComponent,
  DateTimePickerModel,
} from "@syncfusion/ej2-react-calendars";
import { FieldInputProps } from "formik";
import dayjs from "@/utils/date/dayjs.config";
import { L10n, loadCldr } from "@syncfusion/ej2-base";
import * as gregorian from "@pages/calendar/ca-gregorian.json";
import * as numbers from "@pages/calendar/numbers.json";
import * as timeZoneNames from "@pages/calendar/timeZoneNames.json";
import * as numberingSystems from "@pages/calendar/numberingSystems.json";
import * as weekData from "@pages/calendar/weekData.json";
import { Box, styled, Typography } from "@mui/material";
import "./syncfusion-date-picker-styles.css";
import { useState } from "react";
loadCldr(numberingSystems, gregorian, numbers, timeZoneNames, weekData);

L10n.load({
  fr: {
    datetimepicker: {
      today: "Aujourd'hui",
    },
    datepicker: {
      today: "Aujourd'hui",
    },
  },
});

type CustomDatePickerProps = {
  field: FieldInputProps<string>;
  attributes: DatePickerModel;
  onChange: (e: Date) => void;
  labelText: string | JSX.Element;
};
const StyledContainer = styled(Box, {
  shouldForwardProp: props => props !== "isFocused",
})<{ isFocused: boolean }>(({ theme, isFocused }) => ({
  borderColor: `${theme.palette.grey[400]}`,
  borderBottomColor: isFocused ? "transparent" : `${theme.palette.grey[400]}`,
  borderWidth: "1px 1px 2px 1px",
  borderStyle: "solid",
  borderRadius: isFocused ? "5px 5px 0 0" : "5px",
  paddingBottom: 0,
  background: theme.palette.background.default,
  transition: "all .4s linear",
}));

const FloatingLabel = styled(Typography)(({ theme }) => ({
  marginLeft: 12,
  marginTop: -10,
  background: theme.palette.background.default,
  width: "fit-content",
  padding: "0 4px",
}));

const CustomDatePicker = ({ field, attributes, onChange, labelText }: CustomDatePickerProps) => {
  const { locale } = useIntl();
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  return (
    <StyledContainer isFocused={isInputFocused}>
      {labelText && <FloatingLabel variant="subtitle1">{labelText}</FloatingLabel>}
      <DatePickerComponent
        {...field}
        {...attributes}
        onChange={(e: any) => {
          onChange(dayjs(e.target.value as string).toDate());
        }}
        onFocus={() => setIsInputFocused(true)}
        onBlur={() => setIsInputFocused(false)}
        format={locale === "en-US" ? "MM/dd/yyyy" : "dd/MM/yyyy"}
        locale={locale === "en-US" ? "en-US" : "fr"}
        style={{ marginBottom: 0 }}
      />
    </StyledContainer>
  );
};

type CustomDateTimePickerProps = {
  field: FieldInputProps<string>;
  attributes: DateTimePickerModel;
  onChange: (e: Date) => void;
  labelText: string | JSX.Element;
};
const CustomDateTimePicker = ({
  field,
  onChange,
  attributes,
  labelText,
}: CustomDateTimePickerProps) => {
  const { locale } = useIntl();
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);

  return (
    <StyledContainer isFocused={isInputFocused}>
      {labelText && (
        <FloatingLabel variant="subtitle1" sx={{ marginLeft: 1 }}>
          {labelText}
        </FloatingLabel>
      )}
      <DateTimePickerComponent
        {...field}
        {...attributes}
        onChange={(e: { target: { value: string } }) => {
          onChange(dayjs(e.target.value).toDate());
        }}
        onFocus={() => setIsInputFocused(true)}
        onBlur={() => setIsInputFocused(false)}
        style={{ marginBottom: 0 }}
        locale={locale === "en-US" ? "en-US" : "fr"}
        format={locale === "en-US" ? "MM/dd/yyyy HH:mm" : "dd/MM/yyyy HH:mm"}
      />
    </StyledContainer>
  );
};

export { CustomDatePicker, CustomDateTimePicker };
